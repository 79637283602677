<template>
  <div class="menuTemplate">
    <section>
      <div v-if="featuredScreen">
        <featuredParent :featuredScreen="featuredScreen"></featuredParent>
      </div>
      <div v-for="(item, index) in categoryScreens" :key="index">
        <!-- <continueSlider
          v-if="showContinueSlider(item)"
          :continueFire="continueWatchContent"
          :screen="item"
        ></continueSlider>-->
        <continueSlider
          v-if="
            (item.sectionType === 'CONTINUEWATCH' || item.sectionType === 'CONTINUEWATCHING') && (item.status == 'ACTIVE' || item.isSectionEnabled) &&
              subscriberid &&
              getFirebaseUserUID
          "
          :screen="item"
          :playerInstance="playerInstance"
        ></continueSlider>
        <slider
          v-if="
            (item.sectionType !== 'CONTINUEWATCH'&& item.sectionType !== 'CONTINUEWATCHING')  &&
              (item.isSectionEnabled || item.status == 'ACTIVE') &&
              (!item.userTypes ||
                (item.userTypes && item.userTypes.includes('ALL')) ||
                (item.userTypes && item.userTypes.includes(getUserType())))
          "
          :screen="item"
          :id="index"
          :playerInstance="playerInstance"
          :screenName="screens.id"
        ></slider>
      </div>
      <!-- <intersect @enter="inters">
        <div></div>
      </intersect> -->
      <div class="container">
        <p
          class="back-to-top"
          :class="localDisplayLang === 'ara' ? 'back-to-top-rtl' : ''"
          :style="[
            showHoveredColor
             ? { color: 'rgba(0, 0, 0, 0.7)', 'background-color': '#ec1c65' }
              : { color: '#ec1c65' },
          ]"
          @click="scrollTop"
          @mouseover="hoverEffect"
          @mouseleave="noHoverEffect"
        >
           <span class="btn-txt-color">{{ $t("back to top") }} </span>
          <span class="btn-txt-color">
            <BackToTopCopy
              class="rotate-img"
              :showHoveredColor="showHoveredColor"
            ></BackToTopCopy>
          </span>
        </p>
        <!-- <BackToTop
          @scrollTop="scrollTop"
          class="back-to-top"
          :class="localDisplayLang === 'ara' ? 'back-to-top-rtl' : ''"
        ></BackToTop>-->
      </div>
    </section>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
//import Intersect from "vue-intersect";
export default {
  props: {
    screens: {
      type: Object,
    },
    menu: {
      type: [Object, String],
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      enableTemplate: false,
      templates: [],
      featuredScreen: "",
      categoryScreens: [],
      localDisplayLang: null,
      showHoveredColor: false,
      subscriptions: [],
      // lowCounter: 0,
      // highCounter: 1,
      // multiplyValue: 3,
     
    };
  },
  computed: {
    ...mapGetters([
      "subscriberid",
      "getToken",
      "getRtl",
      "subscriptionList",
      "getFirebaseUserUID",
			"appConfig"
    ]),
  },
  watch: {
    subscriberid(val) {
      if (val) {
        this.renderTemplate();
      }
    },
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
      }
    },
  },
  created() {
    console.log('Menu Template Screens =============',this.appConfig)
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });

    eventBus.$on("rerender-template", () => {
      //fetching screens of that particular menu.
      this.renderTemplate();
      //toggling the menu filter component.
      this.renderMenuFilter();
    });

    //fetching screens of that particular menu.
    this.renderTemplate();

    //toggling the menu filter component.
    this.renderMenuFilter();
  },
  mounted() {
    // this.loadContinueFireData();

    setTimeout(() => {
      if (
        this.localDisplayLang === "ara" &&
        this.continueWatchData &&
        this.continueWatchData.length < 5
      ) {
        console.log(
          "continue carousel",
          document.getElementById("continueWatch-carousel-iw")
        );
        document.getElementById("continueWatch-carousel-iw")
          ? (document.getElementById(
              "continueWatch-carousel-iw"
            ).style.textAlign = "-webkit-right")
          : "";
      }
    }, 700);
  },
  methods: {
    // inters(){
    //   console.log('Call the next set data using intersection api ');
    //   this.callNextSetCategories();
    // },
    // callNextSetCategories(){
    //     let nextSetData = this.getSlicedSections(this.categorySections);
    //     this.passDataToCarousal(nextSetData);
    // },
    getUserType() {
      if (!this.subscriberid) {
        return "GUEST";
      } else if (this.subscriberid && this.subscriptions.length > 0) {
        return "SUBSCRIBED";
      } else {
        return "REGISTERED";
      }
    },
    //Assigning screens of featured carousel and category slider based on different categories.
		renderTemplateUsingDecking () {
      console.error("SCREENS",this.screens);


      this.featuredScreen = this.screens.sections[
        this.screens.sections.findIndex((element) => {
          return element.listType == "CAROUSEL";
        })
      ];
      this.categoryScreens = this.screens.sections.filter((element) => {
        if ((element.sectionType == "FILTER" && element.listType == "LIST") || element.sectionType == "CONTINUEWATCHING") {
          return element;
        }
				else if((element.itemType == "TRAILERS" && element.listType == "LIST") || element.sectionType == "CONTINUEWATCHING"){
					return element;
				}
      });
    },
    renderTemplate() {
			if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
			this.renderTemplateUsingDecking();
			return;
      }
      console.log("Screen sections ===== ", this.screens.sections);
      this.featuredScreen = this.screens.sections[
        this.screens.sections.findIndex((element) => {
          return element.sectionType === "FEATURED";
        })
      ];
      this.categoryScreens = this.screens.sections.filter((element) => {
        return element.sectionType !== "FEATURED";
      });

      console.log("Category sections", this.categorySections);

      // let finalSectionsData = this.getSlicedSections(this.categorySections);
      // this.passDataToCarousal(finalSectionsData);

      // setTimeout(() => {
      //   let nextSetData = this.getSlicedSections(this.categorySections);
      //   this.passDataToCarousal(nextSetData);
      // }, 6000);

      //  setTimeout(()=>{
      //   let nextSetData = this.getSlicedSections(this.categorySections)
      //   this.passDataToCarousal(nextSetData)
      //  },12000)

      //  setTimeout(()=>{
      //   let nextSetData = this.getSlicedSections(this.categorySections)
      //   this.passDataToCarousal(nextSetData)
      //  },18000)
    },

    // getSlicedSections(sections) {
    //   let finalSections = this.categorySections.slice(
    //     this.lowCounter * this.multiplyValue,
    //     this.highCounter * this.multiplyValue
    //   );
    //   this.lowCounter = this.lowCounter + 1;
    //   this.highCounter = this.highCounter + 1;
    //   console.log("LOW COUNTER", this.lowCounter);
    //   console.log("HIGH Counter", this.highCounter);
    //   return finalSections;
    // },

    // passDataToCarousal(data) {
    //   data.forEach((element) => {
    //     this.categoryScreens.push(element);
    //   });
    // },
    //toggling the menu filter component based on the hasFilter property in each menu object.
    renderMenuFilter() {
      let payload = {};
      if (!this.menu.hasOwnProperty("hasFilter")) {
        payload.state = false;
      } else if (this.menu.hasOwnProperty("hasFilter") && this.menu.hasFilter) {
        payload.state = true;
        payload.menu = this.menu;
      } else if (this.menu === null) {
        payload.state = false;
      } else {
        payload.state = false;
      }
      eventBus.$emit("toggleMenuFilter", payload);
      window.scrollTo(0, 0);
    },
    // showContinueSlider(data) {
    //   return data.sectionType === "CONTINUEWATCH" &&
    //     data.isSectionEnabled &&
    //     this.continueWatchContent !== null &&
    //     this.continueWatchContent !== undefined &&
    //     this.continueWatchContent.length > 0
    //     ? true
    //     : false;
    // },
    // loadContinueFireData() {
    //   if (firebase.apps.length !== 0) {
    //     this.continueWatchContent = this.checkContinueWatchContents();
    //   } else {
    //     setTimeout(() => {
    //       this.loadContinueFireData();
    //     }, 500);
    //   }
    // },
    // postDeletionFireContents() {
    //   this.loadContinueFireData();
    // },
    scrollTop() {
      window.scrollTo(0, 0);
    },

    hoverEffect() {
      this.showHoveredColor = true;
    },

    noHoverEffect() {
      this.showHoveredColor = false;
    },
  },

  beforeDestroy() {
    eventBus.$off("rerender-template");
  },

  components: {
   // Intersect,
    featuredParent: () =>
      import(
        /* webpackChunkName: "featured" */ "@/components/Carousels/featuredParent.vue"
      ),
    // FeaturedCarousel: () =>
    //   import(
    //     /* webpackChunkName: "featured" */ "@/components/Carousels/FeaturedCarousel.vue"
    //   ),
    slider: () =>
      import(
        /* webpackChunkName: "carousel" */ "@/components/categorySlider/slider.vue"
      ),
    continueSlider: () =>
      import(
        /* webpackChunkName: "contslider" */ "@/components/categorySlider/continueSlider.vue"
      ),
    // DetailPopup: () =>
    //   import(
    //     /* webpackChunkName: "detail" */ "@/components/MobileTemplates/DetailPopup.vue"
    //   ),
    BackToTopCopy: () =>
      import(
        /* webpackChunkName: "backtotop" */ "@/components/SvgImages/BackToTopCopy.vue"
      ),
  },
  // mixins: [FirebaseActions]
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.menuTemplate {
  padding: 0px;
  .container {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
    .back-to-top {
      float: right;
      cursor: pointer;
      font-size: 0.8rem;
      font-family: $font-regular;
      font-weight: 600;

      border-radius: 4px;
      border: 1px solid $btn-clr;
      padding: 0.3rem 0.8rem;
      span {
        .rotate-img {
          transform: rotate(90deg);
        }
      }
    }
    .inactive-btn {
      color: $btn-clr;
    }
    .active-btn {
      color: $clr-dark-hlt;
      background-color: $btn-clr;
    }
  }
}

.menuTemplate {
  .container {
    .back-to-top-rtl {
      float: left;
    }
  }
}
</style>
