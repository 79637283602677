var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menuTemplate"},[_c('section',[(_vm.featuredScreen)?_c('div',[_c('featuredParent',{attrs:{"featuredScreen":_vm.featuredScreen}})],1):_vm._e(),_vm._l((_vm.categoryScreens),function(item,index){return _c('div',{key:index},[(
          (item.sectionType === 'CONTINUEWATCH' || item.sectionType === 'CONTINUEWATCHING') && (item.status == 'ACTIVE' || item.isSectionEnabled) &&
            _vm.subscriberid &&
            _vm.getFirebaseUserUID
        )?_c('continueSlider',{attrs:{"screen":item,"playerInstance":_vm.playerInstance}}):_vm._e(),(
          (item.sectionType !== 'CONTINUEWATCH'&& item.sectionType !== 'CONTINUEWATCHING')  &&
            (item.isSectionEnabled || item.status == 'ACTIVE') &&
            (!item.userTypes ||
              (item.userTypes && item.userTypes.includes('ALL')) ||
              (item.userTypes && item.userTypes.includes(_vm.getUserType())))
        )?_c('slider',{attrs:{"screen":item,"id":index,"playerInstance":_vm.playerInstance,"screenName":_vm.screens.id}}):_vm._e()],1)}),_c('div',{staticClass:"container"},[_c('p',{staticClass:"back-to-top",class:_vm.localDisplayLang === 'ara' ? 'back-to-top-rtl' : '',style:([
          _vm.showHoveredColor
           ? { color: 'rgba(0, 0, 0, 0.7)', 'background-color': '#ec1c65' }
            : { color: '#ec1c65' } ]),on:{"click":_vm.scrollTop,"mouseover":_vm.hoverEffect,"mouseleave":_vm.noHoverEffect}},[_c('span',{staticClass:"btn-txt-color"},[_vm._v(_vm._s(_vm.$t("back to top"))+" ")]),_c('span',{staticClass:"btn-txt-color"},[_c('BackToTopCopy',{staticClass:"rotate-img",attrs:{"showHoveredColor":_vm.showHoveredColor}})],1)])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }